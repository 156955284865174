import React from "react"
// import Shop from "../components/Shop"
import ShopItem from "../components/Shop/ShopItem"
import ShopItemList from "../components/Shop/ShopItemList"
import { Container } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/Layout/layout"
import { Router } from "@reach/router"
import { graphql } from "gatsby"

const ShopNow = ({ templateItems }) => {
  // return <Shop />
  return <ShopItemList templateItems={templateItems} />
}

const ShopNowItem = () => {
  return <ShopItem />
}

const Shop = ({ classes, data }) => {
  // const itemList = data.allMdx.nodes
  const templateItems = data.allMdx.nodes
    .filter(
      i =>
        i.exports.productInfo &&
        i.exports.productInfo.options &&
        i.exports.productInfo.options.filter(po => po.itemNumber !== null)
    )
    .map(ti => {
      return {
        slug: ti.frontmatter.slug,
        itemNumber: ti.exports.productInfo.options.map(o => {
          return o.itemNumber
        }),
      }
    })
  return (
    <Layout>
      <Container className={classes.container}>
        <Router>
          <ShopNow exact path="/shop" templateItems={templateItems} />
          <ShopItem path="/shop/:id" />
        </Router>
      </Container>
    </Layout>
  )
}
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),

    // margin: theme.spacing(1, 0, 0),
  },
  container: {
    marginTop: 25,
    // display: "grid",
  },
})

export const productQuery = graphql`
  {
    allMdx {
      nodes {
        frontmatter {
          slug
        }
        exports {
          productInfo {
            options {
              itemNumber
            }
          }
        }
      }
    }
  }
`
export default withStyles(styles)(Shop)
