import React, { useState, useContext, useEffect } from "react"

import gql from "graphql-tag"
import graphql from "gatsby"
import { useQuery } from "@apollo/client"
import Loading from "../Shared/Loading"
import Error from "../Shared/Error"

import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"
import Pagination from "@material-ui/lab/Pagination"
import { useSiteMetadata } from "../utils/hooks/useSiteMetadata"
import ShopItemCard from "./ShopItemCard"

import SortContext from "../../context/SortContext"

// import NoPrivateItems from "./NoPrivateItems"

const ShopItemList = ({ classes, templateItems, setCurrentTab }) => {
  const { state, dispatch } = useContext(SortContext)
  const searchContext = state.search
  const page = state.page
  const pageSize = state.pageSize

  const [searchBar, setSearchBar] = useState(searchContext)
  const [shopItems, setShopItems] = useState("")
  const itemCount = shopItems.count ? shopItems.count : undefined
  const { sbuId } = useSiteMetadata()
  useEffect(() => {
    dispatch({ type: "SET_PAGE", payload: 1 })
    dispatch({ type: "SET_PAGE_SIZE", payload: 9 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])

  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
    setSearchBar("")
  }

  const { data, loading, error } = useQuery(SHOP_ITEMS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      page: page,
      pageSize: pageSize,
      search: searchContext,
      sbuId: sbuId,
    },
    onCompleted: data => {
      setShopItems(data.shopItems)
      console.log(sbuId)
      console.log(data)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <>
      <div className={classes.root}>
        {JSON.stringify(templateItems)}
        <Grid container spacing={3}>
          <Grid item container justify="space-between" xs={12}>
            <Grid item xs={12} sm={2}>
              <Box mb={1}>
                <Typography variant="caption">
                  Found {itemCount} {itemCount !== 1 ? "items" : "item"}.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              {shopItems.pages > 1 && (
                <Pagination
                  count={shopItems.pages}
                  page={shopItems.page}
                  onChange={handleChange}
                  hideNextButton={!shopItems.hasNext}
                  hidePrevButton={!shopItems.hasPrev}
                  shape="rounded"
                  variant="outlined"
                  size="medium"
                  color="secondary"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              {shopItems.pages > 1 && (
                <Paper
                  square
                  component="form"
                  onSubmit={event => {
                    event.preventDefault()
                    handleSearch(event)
                  }}
                  className={classes.searchField}
                >
                  <InputBase
                    fullWidth
                    className={classes.input}
                    placeholder="Search Items"
                    inputProps={{ "aria-label": "search items" }}
                    onChange={event => setSearchBar(event.target.value)}
                    value={searchBar}
                  />
                  <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    aria-label="clear"
                    onClick={handleClearSearchResults}
                  >
                    <CancelIcon />
                  </IconButton>
                </Paper>
              )}
            </Grid>
          </Grid>
          <>
            {shopItems.count > 0 &&
              shopItems.objects.map(si => {
                const inumb = parseInt(si.id)
                const pageFilter = templateItems.find(ti =>
                  ti.itemNumber.includes(inumb)
                )
                return (
                  <Grid item xs={12} sm={4} key={si.id}>
                    <ShopItemCard si={si} pageFilter={pageFilter} />
                  </Grid>
                )
              })}
          </>

          <Grid item container xs={12} justify="flex-end" alignItems="flex-end">
            <Grid item xs={12} sm={4}>
              {/* <Box className={classes.pagination}>
                {myPrivateItems.pages > 1 && (
                  <Pagination
                    count={myPrivateItems.pages}
                    page={myPrivateItems.page}
                    onChange={handleChange}
                    hideNextButton={!myPrivateItems.hasNext}
                    hidePrevButton={!myPrivateItems.hasPrev}
                    shape="rounded"
                    variant="outlined"
                    size="medium"
                    color="secondary"
                  />
                )}
              </Box> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "grid",
  },
  pagination: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "spaceBetween",
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
})

const SHOP_ITEMS_QUERY = gql`
  query($page: Int, $pageSize: Int, $search: String, $sbuId: Int) {
    shopItems(
      page: $page
      pageSize: $pageSize
      search: $search
      sbuId: $sbuId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        name
        category {
          id
          name
        }
        pricingModel {
          id
          name
          description
        }
        itemPrice {
          id
          minQuantity
          maxQuantity
          price
        }
        featuredImageThumbnailUrl
        featuredImageCarouselImageUrl
        dateModified
        isActive
      }
    }
  }
`
export default withStyles(styles)(ShopItemList)
